<template>
  <p>データローディング</p>
</template>
<script>
import axios from "axios";
import csv from "csvtojson";
export default {
  name: "ProductTable",
  data: () => ({
    data: [],
  }),
  mounted() {
    this.getData();
    this.loadLink();
  },
  methods: {
    async getData() {
      const save = window.localStorage.getItem("timestamp");
      if (save) {
        if (new Date().getTime() - 3250000 < save) {
          this.data = window.localStorage.getItem("data");
          return;
        }
      }
      await this.fetchData();
    },
    async fetchData() {
      const data = await axios
        .get("https://cova.sakuraweb.com/data/data.php")
        .then();
      this.data = data.data;
      window.localStorage.setItem("data", this.data);
      window.localStorage.setItem("timestamp", new Date().getTime());
    },
    loadLink() {
      csv({ delimiter: "\t" })
        .fromString(this.data)
        .then((csv) => {
          const that = csv.find((e) => e.id === "2");
          if (!that) {
            return;
          }
          const relationlinks = csv.filter(
            (e) =>
              e.modelgroup === that.modelgroup &&
              e.destination === that.destination
          );
          window.localStorage.setItem(
            "relation_" + that.id,
            JSON.stringify(relationlinks)
          );
          const resolvedRoute = this.$router.resolve({
            name: "ModelClass",
            query: { id: that.id, r_id: that.id },
          });
          window.open(resolvedRoute.href);
        });
    },
  },
};
</script>
