<template>
  <div class="modelclass">
    <!-- haha -->
    <mcmc ref="mc" />
  </div>
</template>

<script>
// @ is an alias to /src
import mcmc from "@/components/TheModelLink";

export default {
  name: "ModelClass",
  components: {
    mcmc,
  },
};
</script>
